<template>
  <s-drawer
    :id="`cccCouponInfoDrawer-${page}`"
    custom-class="cccxfreeShipping-couponDialog drawer__no-bottom"
    max-size="70%"
    :type="'multi'"
    :append-to-body="true"
    v-model:visible="localVisible"
    @close="closeDrawer"
  >
    <template #title>
      <div
        class="freeshippingCouponTitle"
        v-html="title"
      >
      </div>
    </template>
    <template v-if="localVisible && couponInfo && couponInfo.length">
      <template
        v-for="(coupon, index) in couponInfo"
        :key="`${coupon.coupon}_${coupon.bind_time}_${index}`"
      >
        <!-- 凑单和订单返券不显示倒计时 -->
        <CouponItemBff
          v-if="coupon.applyFor"
          :data="coupon"
          :language="couponLanguage"
          :expanded="''"
          :couponConfig="couponConfig"
          isForBff
        />
      </template>
    </template>
    <template #footer>
      <s-button
        class="freeshipping-cancel-btn"
        :width="'100%'"
        @click="closeDrawer"
      >
        {{ language.SHEIN_KEY_PWA_21364 }}
      </s-button>
    </template>
  </s-drawer>
</template>
<script>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { defineComponent, nextTick } from 'vue'
import couponItemVue from 'public/src/pages/components/coupon/mall/index.vue'
import CouponItemBff from 'public/src/pages/common/common-coupon/CouponItemBff.vue'
import { template } from '@shein/common-function'
export default defineComponent({
  components: {
    SButton,
    SDrawer,
    couponItem: couponItemVue,
    CouponItemBff
  },
  emits: ['update:visible'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    couponInfo: {
      type: Array,
      default() {
        return []
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    couponLanguage: {
      type: Object,
      default() {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    page: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      localVisible: this.visible,
      couponConfig: {
        activityFrom: 'me_coupon_Component',
        buttonInfo: 0,
        assistInformationType: 1,
        add_module: 1, // 附加组件 
      },
    }
  },
  computed: {
    isShowCoupondown() {
      // 只有优惠券倒计时类型才展示倒计时
      return this.item?.type == 6
    }
  },
  watch: {
    visible (newVal) {
      this.localVisible = newVal
    },
    localVisible: {
      handler(newVal) {
        this.$emit('update:visible', newVal)
        if(this.localVisible && this.page === 'category') {
          setTimeout(() => {
            nextTick(() => {
              const scrollCoupon = document.querySelector(`#cccCouponInfoDrawer-${this.page} .sui-drawer__body`)
              scrollCoupon?.addEventListener('touchmove', function(e) {
                e.stopPropagation()
              }, { passive: false })
            })
          }, 500)
        }
      },
      immediate: true,
    }
  },
  created () {
  },
  mounted() {
  },
  updated() {
  },
  methods: {
    template,
    closeDrawer() {
      this.localVisible = false
    }

  },
})
</script>

<style lang="less">
.cccxfreeShipping-couponDialog.drawer__no-bottom {
  border-radius: 6px 6px 0 0;
  .sui-drawer__body {
    .coupon-item {
      margin-bottom: 0.23rem;
    }
  }
  .sui-drawer__header_normal {
    height: 100%;
    min-height: unset;
    line-height: 1;
    border-bottom: none;
  }
  .freeshippingCouponTitle {
    padding: 0.74rem 20px 20px;
    display: inline-block;
    font-size: 16px;
    text-align: center;
    color: #000;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 20px;
    -webkit-line-clamp: 2;
  }
  .promo-color {
    /*sh:begin*/
    color: #FA6338;
    /*rw:begin*/
    color: #FC4070;
  }
  .sui-drawer__multi-footer {
    padding: 0 12px 6px;
  }
  .freeshipping-cancel-btn {
    border: 0px;
    color: @sui_color_white;
    /*sh:begin*/
    background: @sui_color_gray_dark1;
    /*rw:begin*/
    background: #FC4070;
  }
}
</style>
